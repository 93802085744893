<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('elearning_tpm.training_material_info') }} {{ $t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col lg="6" sm="12">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="fiscal_year_id"
            >
              <template v-slot:label>
                {{$t('elearning_config.fiscal_year')}}
              </template>
              <v-select name="organization"
                v-model="search.fiscal_year_id"
                label="text"
                :reduce="item => item.value"
                :options= fiscalYearList
                :placeholder="$t('globalTrans.select')"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
            <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('elearning_config.organization')"
                label-for="organization"
            >
              <v-select name="organization"
                  v-model="search.org_id"
                  label="text"
                  :reduce="item => item.value"
                  :options= orgList
                  :placeholder="$t('globalTrans.select')"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="training_type_id"
            >
              <template v-slot:label>
                {{$t('elearning_config.training_type')}}
              </template>
              <b-form-select
                plain
                v-model="search.training_type_id"
                :options="trainingTypeList"
                id="training_type_id"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="training_title_id"
              >
                <template v-slot:label>
                  {{$t('elearning_config.training_title')}}
                </template>
                <b-form-select
                  plain
                  v-model="search.training_title_id"
                  :options="trainingTitleList"
                  id="training_title_id"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="batch_id"
              >
                <template v-slot:label>
                  {{$t('elearning_config.batch')}}
                </template>
                <b-form-select
                  plain
                  v-model="search.batch_id"
                  :options="batchList"
                  id="batch_id"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
            </b-form-group>
          </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" sm="12" class="text-right">
              <b-button size="sm" type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
            </b-col>
      </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('elearning_tpm.training_material_info') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <router-link class="btn-add" to="training-material-form"><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</router-link>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(status)="data">
                      {{ getStatusName(data.item.status) }}
                  </template>
                  <template v-slot:cell(from_date)="data">
                      <span v-if="data.item.from_date">{{data.item.from_date | dateFormat}}</span>
                  </template>
                   <template v-slot:cell(to_date)="data">
                      <span v-if="data.item.to_date">{{data.item.to_date | dateFormat}}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button v-b-modal.modal-4 variant="iq-bg-success mr-1" size="sm" @click="details(data.item)" class="moc-action-btn moc-view-btn" :title="$t('globalTrans.view')"><i class="ri-eye-line"></i></b-button>
                    <router-link variant="iq-bg-success mr-1" size="sm" :to="{name: 'training_e_learning_service.tpm.training_material_form', params: {id: data.item.id}}" class="action-btn edit" :title="$t('globalTrans.edit')"><i class="ri-pencil-fill"></i></router-link>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
   <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <EditForm :items="items" :key="editItemId"/>
    </b-modal>
   <!-- <b-modal id="modal-4" size="lg" hide-footer :title="$t('elearning_tpm.training_schedule') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <template #modal-title class="flex-grow-1">
            {{ $t('elearning_tpm.training_material_info') }}
        </template>
    <Details :id="editItemId" :key="editItemId"  ref="details"/>
  </b-modal> -->
  <b-modal id="modal-4" size="lg" hide-footer :title="$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Details :item="items" :key="editItemId" ref="details"/>
  </b-modal>
  </div>
</template>
<script>
import Details from './Details'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingMaterialInfoList, trainingMaterialInfoInfo } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/route-based-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details
  },
  data () {
    return {
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        search: {
          fiscal_year_id: this.$store.state.currentFiscalYearId,
          limit: 10,
          org_id: [],
          training_type_id: 0,
          batch_id: 0,
          training_title_id: 0

      },
      batchList: [],
      trainingCategoryList: [],
      trainingTitleList: [],
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '5%' } },
        { labels: 'elearning_config.fiscal_year', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '10%' } },
        { labels: 'elearning_iabm.circular_memo_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '12%' } },
        { labels: 'elearning_config.training_title', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '25%' } },
        { labels: 'elearning_config.batch', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 7, thStyle: { width: '10%' } }
      ],
      items: [],
      viewitemId: 0,
      editItemId: 0,
      actions: {
        edit: true,
        toogle: true,
        delete: false,
        details: true
      }
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    'search.training_type_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    },
    'search.org_id': function (newValue) {
      this.batchList = this.getBatchList(newValue)
    }
  },
  computed: {
    currentLocal () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'fiscal_year_bn' },
          { key: 'circular_memo_no' },
          { key: 'training_title_bn' },
          { key: 'batch_bn' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'fiscal_year' },
          { key: 'circular_memo_no' },
          { key: 'training_title' },
          { key: 'batch' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    formTitle () {
      return this.$t('elearning_tpm.training_material_info') + ' ' + this.$t('globalTrans.modify')
    },
    statusList () {
      const arr = [
        { value: 1, text: this.$t('globalTrans.draft'), text_en: this.$t('globalTrans.draft', 'en'), text_bn: this.$t('globalTrans.draft', 'bn') },
        { value: 2, text: this.$t('globalTrans.pending'), text_en: this.$t('globalTrans.pending', 'en'), text_bn: this.$t('globalTrans.pending', 'bn') },
        { value: 4, text: this.$t('teaGardenPduService.assigned'), text_en: this.$t('teaGardenPduService.assigned', 'en'), text_bn: this.$t('teaGardenPduService.assigned', 'bn') }
      ]
      return arr
    }
  },
  methods: {
    editMethodInParent (item, type) {
        this.items = item
    },
    assignApplication (item) {
      window.vm.$swal({
      title: window.vm.$t('teaGardenPduService.prepare_m_e_status'),
      showCancelButton: true,
      confirmButtonText: window.vm.$t('globalTrans.yes'),
      cancelButtonText: window.vm.$t('globalTrans.no'),
      focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.assignSubmit(item)
        }
      })
    },
    assignSubmit (item) {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(trainingElearningServiceBaseUrl, `${trainingMaterialInfoInfo}/${item.id}`).then(response => {
          if (response.success) {
            window.vm.$toast.success({
              title: window.vm.$t('globalTrans.success'),
              message: window.vm.$t('globalTrans.update_msg'),
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
    },
    details (item) {
        this.items = item
    },
    searchData () {
      this.loadData()
    },
    getFiscalYear (yearId) {
      const cateObj = this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1).find(item => item.value === yearId)
      if (cateObj !== undefined) {
        if (this.$i18n.locale === 'bn') {
          return cateObj.text_bn
        } else {
          return cateObj.text_en
        }
      }
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_type_id === categoryId)
      }
      return trainingTitleList
    },
    getBatchList (orgId) {
      const batchList = this.$store.state.TrainingElearning.commonObj.batchList.filter(item => item.status === 1)
      if (orgId) {
        return batchList.filter(item => item.org_id === orgId)
      }
      return batchList
    },
    getStatusName (status) {
      const obj = this.statusList.find(item => item.value === parseInt(status))
      return this.currentLocal === 'en' ? obj?.text_en : obj?.text_bn
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingMaterialInfoList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          const batchObj = this.$store.state.TrainingElearning.commonObj.batchList.find(doc => doc.value === parseInt(item.batch_id))
          const batchData = {}
          if (typeof batchObj !== 'undefined') {
            batchData.batch = batchObj.text_en
            batchData.batch_bn = batchObj.text_bn
          } else {
            batchData.batch = ''
            batchData.batch_bn = ''
          }
          const orgObj = this.$store.state.CommonService.commonObj.componentOrgList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }
          const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            // fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          return Object.assign({}, item, { serial: index }, batchData, fiscalYearData, trainingTitleData, orgData)
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
