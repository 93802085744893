<template>
    <b-container fluid>
        <div>
            <b-overlay :show="loading">
                <b-col lg="12">
                    <b-row>
                        <b-col lg="12" sm="12" class="p-0">
                            <b-table-simple bordered>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{
                                        $t('elearning_config.fiscal_year') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ ($i18n.locale=='bn') ?
                                        item.fiscal_year_bn : item.fiscal_year }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left">{{
                                        $t('externalTraining.circular_memo_no') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left">{{ item.circular_memo_no }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left">{{ $t('globalTrans.organization')
                                        }}</b-th>
                                    <b-td style="width:65%%;" class="text-left">{{ ($i18n.locale=='bn') ? item.org_bn :
                                        item.org }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left bg-light">{{
                                        $t('elearning_config.training_title') }}</b-th>
                                    <b-td style="width:65%%;" class="text-left bg-light">{{ ($i18n.locale=='bn') ?
                                        item.training_title_bn : item.training_title }}</b-td>
                                </b-tr>
                                <b-tr>
                                    <b-th style="width:35%;" scope="row" class="text-left">{{ $t('elearning_config.batch')
                                        }}</b-th>
                                    <b-td style="width:65%%;" class="text-left">{{ ($i18n.locale=='bn') ? item.batch_bn
                                        : item.batch }}</b-td>
                                </b-tr>
                            </b-table-simple>
                            <b-table-simple bordered v-if="item.details.length > 0">
                                <thead>
                                    <tr>
                                        <th scope="col" style="width: 7%"><div>{{ $t('globalTrans.sl_no') }}</div></th>
                                        <th scope="col" style="width: 20%" class="text-center"><div>{{ $t('elearning_tpm.material_name') }}</div></th>
                                        <th scope="col" style="width: 10%" class="text-center"><div>{{ $t('elearning_tpm.unit_price') }}</div></th>
                                        <th scope="col" style="width: 10%" class="text-center"><div>{{ $t('globalTrans.quantity') }}</div></th>
                                        <th scope="col" style="width: 10%" class="text-center"><div>{{ $t('globalTrans.total') }} {{ $t('globalTrans.price') }}</div></th>
                                    </tr>
                                </thead>
                                <b-tbody>
                                    <b-tr v-for="(item, index) in item.details" :key="index">
                                        <td  class="text-center">{{ $n(index+1) }}</td>
                                        <td class="text-center">{{ currentLocale === 'bn' ? item.material_name_bn : item.material_name }}</td>
                                        <td class="text-center">{{ $n(item.unit_price) }}</td>
                                        <td class="text-center">{{ $n(item.quantity) }}</td>
                                        <td class="text-center">{{ $n(item.total_price) }}</td>
                                    </b-tr>
                                </b-tbody>
                                <b-tr>
                                    <b-td class="text-right" colspan="4"><strong>{{ $t('elearning_tpm.grant_total') }}</strong></b-td>
                                    <b-td class="text-center"><strong>{{ $n(item.grant_total_price) }}</strong></b-td>
                                    <!-- <th scope="col" colspan="4" style="width: 15%" class="text-center"><div>{{ $t('elearning_tpm.grant_total_price') }}</div></th>
                                    <td class="text-center">{{ $n(item.grant_total_price) }}</td> -->
                                </b-tr>
                            </b-table-simple>
                        </b-col>
                    </b-row>
                </b-col>
            </b-overlay>
        </div>
    </b-container>
</template>
<style scoped>
.table-form th,
td {
    padding: 3px !important;
    margin: 1px !important;
    vertical-align: middle !important;
    font-size: 15px;
}
</style>
<script>
// import ExportPdf from './export-pdf_details'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingMaterialInfoInfo } from '../../api/routes'

export default {
    components: {
        // ListReportHead
    },
    name: 'FormLayout',
    props: ['item'],
    created () {
    },
    data () {
        return {
            loading: false,
            circular: [],
            fiscal_year: '',
            fiscal_year_bn: '',
            org: '',
            org_bn: '',
            training_title: '',
            training_title_bn: '',
            training_type: '',
            training_type_bn: '',
            slOffset: 1,
            trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
        }
    },
    computed: {
        orgTypeList: function () {
            return [
                {
                    value: 1,
                    text: this.$i18n.locale === 'bn' ? 'এমওসি' : 'MOC',
                    text_en: 'MOC',
                    text_bn: 'এমওসি'
                },
                {
                    value: 2,
                    text: this.$i18n.locale === 'bn' ? 'ব্যক্তিগত' : 'Private',
                    text_en: 'Private',
                    text_bn: 'ব্যক্তিগত'
                },
                {
                    value: 3,
                    text: this.$i18n.locale === 'bn' ? 'অন্যান্য' : 'Others',
                    text_en: 'Others',
                    text_bn: 'অন্যান্য'
                }
            ]
        }
    },
    methods: {
        async appliedListData () {
            this.loading = true
            const params = {
                circular_memo_no: this.item.circular_memo_no,
                batch_id: this.item.batch_id
            }
            RestApi.getData(trainingElearningServiceBaseUrl, trainingMaterialInfoInfo, params).then(response => {
                if (response.success) {
                    this.circular = response.data
                    this.loading = false
                } else {
                    this.loading = false
                    this.circular = []
                }
            })
        }
    }
}
</script>
<style>
.hidden_header {
    display: none
}

.card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
}

.reportTitle {
    font-weight: bold;
    border: 1px solid;
    padding: 8px 15px 8px 15px;
    border-radius: 11px;
}

[dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
}

.report-name {
    font-weight: bold !important;
    text-transform: uppercase;
}

.my-btn {
    padding: 2px !important;
}</style>
